import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";
import NumberFormatIn from "../../Common/NumberFormatIn";
import { apiCall } from "../../../Backend/Backend";

const php = new Php();
const height = window.innerHeight;

export default function LedgerLogs({ clientData }) {
  const [loading, setLoading] = React.useState(false);
  const [clientLedgerLogs, setClientLedgerLogs] = React.useState([]);
  const { addNotification } = useNotification();

  React.useEffect(() => {
    load_users_ledger();
  }, [clientData.id]);

  const load_users_ledger = async () => {
    if (!loading) {
      setLoading(true);
      let sendData = {
        a_id: clientData.id,
      };

      const response = await apiCall("/load_admins_ledger", sendData);
      setLoading(false);

      setClientLedgerLogs(response.ledger_logs);
    }
  };

  return (
    <div className="card">
      <DataTable
        removableSort
        stripedRows
        showGridlines
        scrollHeight={height - 200}
        scrollable
        // filters={filters}
        value={clientLedgerLogs}
        size="small"
      >
        <Column
          style={{ width: "20%" }}
          sortable
          showFilterMenu={false}
          field="remarks"
          header="Remarks"
        ></Column>

        <Column
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="type"
          body={(rowData) =>
            rowData.in_out == 0 ? (
              <div style={{ color: NewTheme.greencolor }}>Deposit</div>
            ) : (
              <div style={{ color: NewTheme.redcolor }}>Withdraw</div>
            )
          }
          header="In/Out"
        ></Column>
        <Column
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="amount"
          body={(rowData) =>
            rowData.amount >= 0 ? (
              <div style={{ color: NewTheme.greencolor }}>
                +<NumberFormatIn value={rowData.amount} />
              </div>
            ) : (
              <div style={{ color: NewTheme.redcolor }}>
                <NumberFormatIn value={rowData.amount} />
              </div>
            )
          }
          header="Amount"
        ></Column>
        <Column
          style={{ width: "30%" }}
          showFilterMenu={false}
          field="type"
          body={(rowData) => (
            <div>
              <NumberFormatIn value={rowData.old_balance} />
              <span
                style={{
                  color: NewTheme.MainColor,
                  fontWeight: "bolder",
                  paddingRight: 5,
                  paddingLeft: 5,
                }}
              >
                {"  ->  "}
              </span>
              <NumberFormatIn value={rowData.new_balance} />
            </div>
          )}
          header="Old->New Bal"
        ></Column>
        <Column
          style={{ width: "10%" }}
          sortable
          showFilterMenu={false}
          field="date_created"
          header="Date/Time"
        ></Column>
      </DataTable>
    </div>
  );
}
