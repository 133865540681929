import React from "react";
import { Sidebar } from "primereact/sidebar";
import styled from "styled-components"; // Import styled-components for styling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../Assets/Images/logo.png";
import { useSidebar } from "./SidebarContext";
import { COLORSCHEMEA } from "../../ColorSchema/ColorSchema";
import { IoIosArrowForward } from "react-icons/io";
import { PiPlugsConnectedThin, PiUserListThin } from "react-icons/pi";

import {
  CiUser,
  CiBookmark,
  CiHome,
  CiBoxes,
  CiBank,
  CiTextAlignRight,
  CiTimer,
  CiLogout,
} from "react-icons/ci";

import {
  faHome,
  faUser,
  faSitemap,
  faCrown,
  faUserCircle,
  faChartPie,
  faChartBar,
  faHandHoldingUsd,
  faWarehouse,
  faEye,
  faUserTie,
  faMoneyBillTrendUp,
  faMoneyBillTransfer,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";

import { FaPowerOff, FaRegUserCircle } from "react-icons/fa";

import "./Mysidebar.css";
import { useLocation, useNavigate } from "react-router-dom";
import { NewTheme } from "../Theme/Theme";
import { useWindowSize } from "@react-hook/window-size";
const SidebarContainer = styled.div`
  background-color: ${NewTheme.MainColor};
  color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; // You can adjust the width as needed
  padding: 1rem;
`;

const SidebarItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  max-height: 50px;
  padding: 15px;
  border: 1px solid transparent;
  transition: border 0.3s, transform 0.3s, color 0.3s, background-color 0.3s,
    border-radius 0.3s;
  background-color: transparent;
  box-sizing: border-box;
  margin-top: 5px;
  svg {
    color: ${NewTheme.lightmain};
    margin-right: 0.5rem;
    transition: color 0.3s;
    padding-left: 10px;
  }

  &:hover {
    border: 1px solid ${COLORSCHEMEA.THEMEFONT};
    background-color: rgba(255, 255, 255, 0.7);
    color: ${COLORSCHEMEA.THEMEFONT};
    border-radius: 4px;

    svg {
      color: ${COLORSCHEMEA.THEMEFONT};
    }
  }

  &.active {
    border: 1px solid ${COLORSCHEMEA.THEMEFONT};
    background-color: white;
    color: ${COLORSCHEMEA.THEMEFONT};
    border-radius: 4px;

    svg {
      color: ${COLORSCHEMEA.THEMEFONT};
    }
  }
`;

const MySidebar = () => {
  const { isOpen, toggleSidebar } = useSidebar();
  const [width, height] = useWindowSize();

  const logoutFunc = () => {
    localStorage.clear();
    window.location = "/";
  };

  const customHeader = (
    <div
      style={{
        borderStyle: "solid",
        width: "100%",
        borderWidth: 0,
        borderBottomWidth: 1,
      }}
    ></div>
    // <div
    //   style={{
    //     height: "100%",
    //     width: "100%",
    //     display: "flex",
    //     alignItems: "center",
    //   }}
    // >
    //   <div style={{ width: 60, height: 60 }}>

    //   </div>
    //   <div
    //     style={{
    //       marginLeft: 10,
    //       fontWeight: "bold",
    //       color: NewTheme.MainColor,
    //       fontSize: 20,
    //     }}
    //   >
    //     {localStorage.getItem("server").toUpperCase()}
    //     <br />
    //     <span style={{ fontSize: 16, color: "black", marginTop: -3 }}>
    //       {localStorage.getItem("mastersUsername")?.toUpperCase()}
    //     </span>
    //   </div>
    // </div>
  );

  const customIcons = (
    <React.Fragment>
      <button
        onClick={() => logoutFunc()}
        className="p-sidebar-icon p-link mr-2"
      >
        <span className="pi pi-power-off" />
      </button>
    </React.Fragment>
  );

  const menuItems = [
    { name: "Home", icon: CiHome, route: "/Home" },
    { name: "Watchlist", icon: CiBookmark, route: "/watchlist" },
    { name: "Admins", icon: faUserCircle, route: "/Admins" },
    { name: "Masters", icon: faCrown, route: "/masters" },
    { name: "Users", icon: CiUser, route: "/users" },
    { name: "Positions", icon: CiBoxes, route: "/positions" },
    { name: "Holdings", icon: CiBank, route: "/holdings" },
    { name: "Transactions", icon: CiTextAlignRight, route: "/transactions" },
    { name: "Pendings", icon: CiTimer, route: "/pendings" },
    { name: "Brokers", icon: PiUserListThin, route: "/brokers" },
    { name: "Brokerage", icon: PiPlugsConnectedThin, route: "/brokerage" },

    // { name: "Watchlist", icon: faEye, route: "/watchlist" },
    // { name: "Deposit", icon: faMoneyBillTrendUp, route: "/deposit" },
    // { name: "Withdraw", icon: faMoneyBillTransfer, route: "/withdraw" },
    // { name: "Users", icon: faUser, route: "/users" },
    // { name: "Super", icon: faSitemap, route: "/super" },
    // { name: "Admin", icon: faUserCircle, route: "/admin" },
    // { name: "Profile", icon: faUserAlt, route: "/profile" },
  ];

  const location = useLocation();
  const navigate = useNavigate();

  const handleMenuItemClick = (item) => {
    navigate(item.route);
    toggleSidebar();
  };

  return (
    <Sidebar
      visible={isOpen}
      onHide={toggleSidebar}
      style={{
        padding: 0,
        margin: 0,
        borderTopRightRadius: 10,
        borderWidth: 0,
        background: COLORSCHEMEA.THEMEFONT,
        color: "white",
      }}
      closeOnEscape={true}
      // icons={customIcons}
      // header={customHeader}
      showCloseIcon={false}
    >
      <div style={{ height: height, display: "flex", flexDirection: "column" }}>
        <div
          style={{ height: 50, marginTop: 30, marginLeft: 10, display: "flex" }}
        >
          <div>
            <img
              src={
                "https://img.dfcapitals.com/" +
                localStorage.getItem("server")?.toLowerCase() +
                ".gif?" +
                new Date()
              }
              style={{ width: 50, height: 50, borderRadius: 25 }}
            />
          </div>

          <div
            style={{
              marginLeft: 5,
              alignSelf: "center",
              fontWeight: "bold",
              flex: 1,
            }}
          >
            {localStorage.getItem("server").toUpperCase()}
          </div>
          <div
            onClick={() => logoutFunc()}
            style={{ width: 40, alignSelf: "center" }}
          >
            <FaPowerOff size={25} />
          </div>
        </div>
        <div
          style={{
            flex: 1,
            marginLeft: 10,
            marginRight: 10,
            paddingTop: 20,
            overflowY: "scroll",
          }}
        >
          {menuItems.map((Item, index) => (
            <SidebarItem
              key={index}
              onClick={() => handleMenuItemClick(Item)}
              className={location.pathname === Item.route ? "active" : ""}
            >
              {/* <Item.icon size={35} /> */}
              {/* <FontAwesomeIcon icon={item.icon} size="lg" /> */}
              <span
                style={{ marginLeft: 10, fontSize: 17, fontWeight: "bold" }}
              >
                {Item.name}
              </span>
            </SidebarItem>
          ))}
        </div>
        <div
          style={{
            height: 50,
            marginTop: 30,
            display: "flex",
            marginBottom: 10,
          }}
        >
          <div
            style={{
              height: 50,
              width: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FaRegUserCircle size={30} />
          </div>
          <div
            style={{
              marginLeft: 5,
              alignSelf: "center",
              fontWeight: "bold",
              flex: 1,
            }}
          >
            {localStorage.getItem("adminsUsername")?.toUpperCase()}
          </div>
          <div
            style={{
              height: 50,
              width: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IoIosArrowForward size={30} />{" "}
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default MySidebar;
