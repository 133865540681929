import React, { useState } from "react";
import Php from "../../Backend/Php";
import { useNotification } from "../Notification/NotificationContext";
import TopbarMost from "../Common/TopbarMost";
import UpdateTrade from "../Common/Update";
import TableHeader from "./TableHeader";
import HoldingCard from "./Card/HoldingCard";
import VolumeCard from "./Card/VolumeCard";
import { NewTheme } from "../Theme/Theme";
import { Button } from "primereact/button";
import HoldingScriptModal from "./Modal/HoldingScriptModal";
import HoldingVolumeModal from "./Modal/HoldingVolumeModal";
import { Dropdown } from "primereact/dropdown";
import { connect } from "react-redux";
import jsPDF from "jspdf";

const php = new Php();
const height = window.innerHeight;
const totalHeight = (height / 100) * 95;

var filterTypeScript = "";
var filterDataScript = "";
var filterArrayScript = "";

var filterTypeSegment = "";
var filterDataSegment = "";
var filterArraySegment = "";

var tableRef = React.createRef();
var tableRef2 = React.createRef();

function Holding(props) {
  const [loading, setLoading] = useState(false);
  const ref = React.useRef();
  const { addNotification } = useNotification();

  const [buyVolume, setBuyVolume] = useState(0);
  const [sellVolume, setSellVolume] = useState(0);
  const [holding, setHolding] = useState([]);
  const [holdingFilter, setHoldingFilter] = useState([]);

  const [holdingScript, setHoldingScript] = useState([]);
  const [modelDataAmountSummary, SetModelDataAmountSummary] = useState("");
  const [symbol, setSymbol] = useState("");

  const [filterSymbol, setFilterSymbol] = React.useState([]);
  const [filterSymbol2, setFilterSymbol2] = React.useState([]);
  const [selectedSymbol, setSelectedSymbol] = React.useState({});

  const [filterSegment, setFilterSegment] = React.useState([
    { id: "0", segment: "FUTURE" },
    { id: "1", segment: "MCX" },
    { id: "2", segment: "OPTION" },
    { id: "3", segment: "FOREX" },
    { id: "4", segment: "CRYPTO" },
    { id: "5", segment: "US.STOCKS" },
  ]);
  const [selectedSegment, setSelectedSegment] = React.useState({});

  React.useEffect(() => {
    load_holdings();
  }, []);

  const calcVolume = (holding) => {
    var totalBuyVolumeWithAvgGreaterThanZero = holding.reduce((total, item) => {
      if (parseFloat(item.buy_avg) > 0) {
        total += parseFloat(item.buy_volume);
      }
      return total;
    }, 0);
    setBuyVolume(totalBuyVolumeWithAvgGreaterThanZero);

    var totalSellVolumeWithAvgGreaterThanZero = holding.reduce(
      (total, item) => {
        if (parseFloat(item.sell_avg) > 0) {
          total += parseFloat(item.sell_volume);
        }
        return total;
      },
      0
    );
    setSellVolume(totalSellVolumeWithAvgGreaterThanZero);
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF({ orientation: "landscape" });
    const tableElement = tableRef.current;
    const tableElement2 = tableRef2.current;

    if (!tableElement || !tableElement2) {
      console.error("Table references are not available.");
      return;
    }

    // 1. Generate table data for the second table (to be positioned at the top)
    const tableData2 = [];

    for (let i = 0; i < tableElement2.children.length; i++) {
      const cellElement = tableElement2.children[i];
      tableData2.push([cellElement.innerText]);
    }

    if (tableData2.length === 0) {
      console.warn("No data found in the second table.");
      return;
    }

    // Transpose tableData2 if needed
    const transposedTableData2 = tableData2[0].map((_, i) =>
      tableData2.map((row) => row[i])
    );

    // Add the second table (transposed) to the PDF at the top
    doc.autoTable({
      body: transposedTableData2,
      startY: 10, // Position the second table at the top
      theme: "grid",
    });

    // 2. Generate table data for the first table
    const tableData = [];

    for (let i = 0; i < tableElement.children.length; i++) {
      const rowElement = tableElement.children[i];
      const rowData = [];

      // Iterate through the cells (divs) of each row
      for (let j = 0; j < rowElement.children.length; j++) {
        const cellElement = rowElement.children[j];
        rowData.push(cellElement.innerText);
      }

      tableData.push(rowData);
    }

    if (tableData.length === 0) {
      console.warn("No data found in the first table.");
      return;
    }

    // Separate header and body rows for the first table
    const columnData = tableData[0];
    const tableBodyData = tableData.slice(1);

    // Add the first table to the PDF below the second table
    const yOffset = doc.previousAutoTable
      ? doc.previousAutoTable.finalY + 10
      : 40;

    doc.autoTable({
      head: [columnData],
      body: tableBodyData,
      startY: yOffset, // Position the first table below the second table
      theme: "grid",
    });

    doc.save("Master_Holdings.pdf");
  };

  const load_holdings = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
      };

      php.load_holdings(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          r.holding.sort((a, b) => {
            const symbolOrder = [
              "NIFTY",
              "BANKNIFTY",
              "FINNIFTY",
              "GOLD",
              "SILVER",
            ];

            const getSymbolPriority = (symbol) => {
              const index = symbolOrder.indexOf(symbol);
              return index !== -1 ? index : symbolOrder.length;
            };

            const priorityA = getSymbolPriority(a.symbol);
            const priorityB = getSymbolPriority(b.symbol);

            if (priorityA !== priorityB) {
              return priorityA - priorityB;
            } else {
              // return a.symbol.localeCompare(b.symbol);
            }
          });
          setHoldingFilter(r.holding);
          SetModelDataAmountSummary(r.segment_summary);

          const uniqueSymbols = new Set();
          const array = r.holding;

          array.forEach((item) => {
            const symbolKey = JSON.stringify({
              symbol: item.symbol,
              script_id: item.script_id,
              segment: item.segment,
            });

            uniqueSymbols.add(symbolKey);
          });

          // To get the unique objects back, you can convert them back from JSON
          const uniqueSymbolObjects = Array.from(uniqueSymbols).map((key) =>
            JSON.parse(key)
          );

          setFilterSymbol(Array.from(uniqueSymbolObjects));
          setFilterSymbol2(Array.from(uniqueSymbolObjects));

          if (filterTypeScript == "script") {
            filterScripts(filterDataScript, r.holding, filterArrayScript);
          } else {
            filterTypeScript = "";
            filterDataScript = "";
            filterArrayScript = "";

            filterTypeSegment = "";
            filterDataSegment = "";
            filterArraySegment = "";

            setHolding(r.holding);
            calcVolume(r.holding);
          }
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const filterSegments = (a, data, selectedUserData) => {
    props.clear_pf();
    if (selectedUserData == undefined || selectedUserData == "") {
      filterTypeSegment = "";
      filterDataSegment = "";
      filterArraySegment = "";

      filterTypeScript = "";
      filterDataScript = "";
      filterArrayScript = "";

      setSelectedSegment({});
      setFilterSymbol(filterSymbol2);
      setHolding(holdingFilter);
      calcVolume(holdingFilter);
    } else {
      filterTypeSegment = "";
      filterDataSegment = "";
      filterArraySegment = "";
      let positionData = [];

      positionData = data.filter((x) => x.segment == a);

      let newData = filterSymbol2.filter((x) => x.segment == a);

      setFilterSymbol(newData);

      setHolding(positionData);
      setSelectedSegment(selectedUserData);
      calcVolume(positionData);
      setSelectedSymbol({});

      filterTypeSegment = "segment";
      filterDataSegment = a;
      filterArraySegment = selectedUserData;

      filterTypeScript = "";
      filterDataScript = "";
      filterArrayScript = "";
    }
  };

  const filterScripts = (a, data, selectedUserData) => {
    props.clear_pf();
    if (selectedUserData == undefined || selectedUserData == "") {
      filterTypeScript = "";
      filterDataScript = "";
      filterArrayScript = "";

      calcVolume(holdingFilter);

      setHolding(holdingFilter);

      setSelectedSymbol({});
    } else {
      filterTypeScript = "";
      filterDataScript = "";
      filterArrayScript = "";

      let positionData = [];

      positionData = holdingFilter.filter((x) => x.script_id == a);

      setHolding(positionData);
      setSelectedSymbol(selectedUserData);
      calcVolume(positionData);

      filterTypeScript = "script";
      filterDataScript = a;
      filterArrayScript = selectedUserData;
    }
  };

  const filterbar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 30,
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
        }}
      >
        <Dropdown
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: 200,
            height: 38,
          }}
          showClear
          onChange={(e) => {
            if (e.value == undefined) {
              filterScripts(e.value, holding, e.value);
            } else {
              filterScripts(e.value?.script_id, holding, e.value);
            }
          }}
          value={selectedSymbol}
          options={filterSymbol}
          optionLabel="symbol"
          placeholder="Filter Script"
          filter
        />
      </div>
      <div ref={tableRef2} style={{ display: "flex" }}>
        <VolumeCard buyVolume={buyVolume} sellVolume={sellVolume} ref={ref} />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          label="PDF"
          style={{
            height: 40,
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 14,
            color: NewTheme.orangecolor,
            backgroundColor: "#fff",
          }}
          onClick={handleGeneratePDF}
          data-pr-tooltip="PDF"
        />
      </div>
    </div>
  );

  const [visible, setVisible] = useState(false);
  const [visibleTotalVolume, setVisibleTotalVolume] = useState(false);
  const [position, setPosition] = useState("top");

  const showTotalVolume = () => {
    setVisibleTotalVolume(true);
  };

  const load_holding_script = (script_id, symbol) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        a_id: localStorage.getItem("adminsId"),
        script_id: script_id,
      };

      php.load_holding_script(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setHoldingScript(r.holding_script);
          setSymbol(symbol);
          setVisible(true);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  return (
    <div className="card">
      <UpdateTrade update={() => load_holdings()} />
      <TopbarMost cmp={filterbar} name={"Holdings"} />

      {/* <TopbarMost noSidebar cmp={searchBar} /> */}
      <div
        className="scroll-css"
        ref={tableRef}
        style={{ overflowY: "scroll", height: totalHeight - 60 }}
      >
        <TableHeader />
        {holding.length > 0
          ? holding.map((i, t) => {
              return (
                <HoldingCard
                  item={i}
                  modelwork={(script_id, symbol) =>
                    load_holding_script(script_id, symbol)
                  }
                  key={i.script_id}
                  total={holding.length}
                />
              );
            })
          : null}
      </div>
      {visible && (
        <HoldingScriptModal
          setVisible={() => setVisible(false)}
          position={position}
          visible={visible}
          symbol={symbol}
          holdingScript={holdingScript}
        />
      )}

      {visibleTotalVolume && (
        <HoldingVolumeModal
          setVisible={() => setVisibleTotalVolume(false)}
          position={position}
          visible={visibleTotalVolume}
          holdingScript={modelDataAmountSummary}
        />
      )}
    </div>
  );
}

const MapStateToDispatch = (dispatch) => {
  return {
    clear_pf: () => {
      dispatch({
        type: "CLEAR_PF3",
      });
    },
  };
};

export default connect(null, MapStateToDispatch)(Holding);
