import React, { useContext, useEffect } from "react";
import SocketContect from "../../SocketContext/SocketContext";
import {
  surveyPlaysound,
  boundryPlaysound,
  fsqoffPlaysounnd,
  pendningplaySound,
} from "../Notification/SoundUtility";

export default function UpdateNotification(props) {
  const testing = (data) => {
    if (data.type == "S") {
      surveyPlaysound();
    } else if (data.type == "P") {
      pendningplaySound();
    } else if (data.type == "L") {
      fsqoffPlaysounnd();
    } else if (data.type == "B") {
      boundryPlaysound();
    }
    props.update();
  };

  const socket = useContext(SocketContect);
  useEffect(() => {
    socket.on("notification", testing);
    return () => {
      socket.off("notification", testing);
    };
  }, []);

  return <div></div>;
}
