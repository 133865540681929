import React from "react";
import styled, { css } from "styled-components";

import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import Php from "../../Backend/Php";
import { NewTheme } from "../Theme/Theme";
import { useNotification } from "../Notification/NotificationContext";
import { CreateClientButton } from "../Users/Overview/Styled";

const php = new Php();

const RightPanelContainer = styled.div`
  flex: 3;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-left: 0px solid black; /* Add a 1px black border on the left side */
`;

export const BalanceWrapper = styled.div`
  /* border-bottom: 1px solid lightgray; */
  /* margin-bottom: 20px; */
  display: flex; /* Make child elements horizontal */
  flex-direction: column;
  width: 90%;
  justify-content: space-between; /* Add space between children */
`;

export const BalanceContainer = styled.div`
  margin-bottom: 20px;
  display: flex; /* Make child elements horizontal */
  align-items: center; /* Vertically center align child elements */
  justify-content: space-between; /* Add space between children */
`;

export const BalanceInput = styled.input`
  background-color: white;
  color: rgb(0, 0, 0);
  transition: all 0.2s ease 0s;
  height: 40px;
  border-radius: 5px;
  width: 50%;
  border: 0.5px solid lightgray;
  padding-left: 10px;
  ${(props) =>
    props.disabled &&
    css`
      background-color: #edebfa;
      color: ${NewTheme.MainColor};
      font-weight: bold;
    `}
`;

export const BalanceUpdateButton = styled.button`
  display: flex; /* Center content horizontally and vertically */
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 150px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  color: white;
  padding: 14px 28px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  margin: 0px;
  background-color: ${NewTheme.MainColor};
  border-color: ${NewTheme.MainColor};
  align-self: center;

  &:focus-visible {
    border-color: #6366f1;
    color: #ffffff;
    border-width: 3px;
    border: 1px solid #ffffff;
  }
`;

export const BalanceLabel = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 10px;
  color: gray;
  text-align: left;
`;

export const BalanceAmountText = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 20px;
  color: gray;
`;

export default function Payment(props) {
  const [loading, setLoading] = React.useState(false);
  const [paymentData, setPaymentData] = React.useState([]);
  const { addNotification } = useNotification();
  const [formData, setFormData] = React.useState({
    status: "",
    upi_status: "",
    upi_1: "",
    upi_2: "",
    bank_status: "",
    bank_name: "",
    account_holder_name: "",
    account_no: "",
    ifsc_code: "",
    whats_app_number: "",
    login_link: "",
    tab_header: "",
    tab_logo: "",
  });

  React.useEffect(() => {
    load_payment();
  }, []);

  const load_payment = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        a_id: localStorage.getItem("adminsId"),
      };

      php.load_payment(data).then((r) => {
        if (r.error === "False") {
          const paymentData = r.payment;
          setFormData((prevFormData) => ({
            ...prevFormData,
            status: paymentData.status || "",
            upi_status: paymentData.upi_status || "",
            upi_status: paymentData.upi_status || "",
            upi_1: paymentData.upi_1 || "",
            upi_2: paymentData.upi_2 || "",
            bank_status: paymentData.bank_status || "",
            bank_name: paymentData.bank_name || "",
            account_holder_name: paymentData.account_holder_name || "",
            account_no: paymentData.account_no || "",
            ifsc_code: paymentData.ifsc_code || "",
            whats_app_number: paymentData.whats_app_number || "",
            login_link: paymentData.login_link || "",
            tab_header: paymentData.tab_header || "",
            tab_logo: paymentData.tab_logo || "",
          }));
        } else {
          addNotification(r.message, "error");
        }
        setLoading(false);
      });
    }
  };

  const edit_payment = (e) => {
    if (!loading) {
      setLoading(false);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        a_id: localStorage.getItem("adminsId"),
        form_data: formData,
      };

      php.edit_payment(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          load_payment();
          addNotification(r.message, "success");
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Toggle values of upi_status and bank_status
    if (name === "upi_status" || name === "bank_status" || name === "status") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value == "0" ? "1" : "0",
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  return (
    <div
      style={{
        width: "100%",
        background: "white",
        height: "100%",
        // padding: 10,
        boxSizing: "border-box",
      }}
    >
      <Card
        style={{
          margin: 10,
          position: "relative",
          height: window.innerHeight - 160,
          overflowY: "scroll",
        }}
      >
        <div style={{ display: "flex" }}>
          <RightPanelContainer>
            <BalanceWrapper>
              <BalanceContainer>
                <BalanceLabel>DEPOSIT DETAILS </BalanceLabel>
                <CreateClientButton
                  active={formData?.status == "1" ? true : false}
                  color={
                    formData?.status == "1"
                      ? NewTheme.greencolor
                      : NewTheme.redcolor
                  }
                  minWidth={150}
                  onClick={() =>
                    handleChange({
                      target: {
                        name: "status",
                        value: formData.status,
                      },
                    })
                  }
                >
                  {formData?.status == "1" ? "ACTIVE" : "INACTIVE"}
                </CreateClientButton>
              </BalanceContainer>

              <BalanceContainer>
                <BalanceLabel>SHOW UPI </BalanceLabel>
                <CreateClientButton
                  active={formData?.upi_status == "1" ? true : false}
                  color={
                    formData?.upi_status == "1"
                      ? NewTheme.greencolor
                      : NewTheme.redcolor
                  }
                  minWidth={150}
                  onClick={() =>
                    handleChange({
                      target: {
                        name: "upi_status",
                        value: formData.upi_status,
                      },
                    })
                  }
                >
                  {formData?.upi_status == "1" ? "ACTIVE" : "INACTIVE"}
                </CreateClientButton>
              </BalanceContainer>
              <BalanceContainer>
                <BalanceLabel>UPI ID 1 </BalanceLabel>
                <BalanceInput
                  type="text"
                  value={formData?.upi_1}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: "upi_1",
                        value: e.target.value,
                      },
                    })
                  }
                  placeholder="abc@abcbank"
                />
              </BalanceContainer>
              <BalanceContainer>
                <BalanceLabel>UPI ID 2 </BalanceLabel>
                <BalanceInput
                  type="text"
                  value={formData?.upi_2}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: "upi_2",
                        value: e.target.value,
                      },
                    })
                  }
                  placeholder="abc@abcbank"
                />
              </BalanceContainer>

              <BalanceContainer>
                <BalanceLabel>Deposit Support No. </BalanceLabel>
                <BalanceInput
                  type="text"
                  value={formData?.whats_app_number}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: "whats_app_number",
                        value: e.target.value,
                      },
                    })
                  }
                  placeholder="0010020020"
                />
              </BalanceContainer>

              {/* <BalanceContainer>
                <BalanceLabel>Deposit Support No. </BalanceLabel>
                <BalanceInput
                  type="text"
                  value={formData?.tab_header}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: "tab_header",
                        value: e.target.value,
                      },
                    })
                  }
                  placeholder="YOURCOMPANY"
                />
              </BalanceContainer> */}
            </BalanceWrapper>
          </RightPanelContainer>
          <RightPanelContainer>
            <BalanceWrapper>
              <BalanceContainer>
                <BalanceLabel>SHOW BANK DETAILS </BalanceLabel>
                <CreateClientButton
                  active={formData?.bank_status == "1" ? true : false}
                  color={
                    formData?.bank_status == "1"
                      ? NewTheme.greencolor
                      : NewTheme.redcolor
                  }
                  minWidth={150}
                  onClick={() =>
                    handleChange({
                      target: {
                        name: "bank_status",
                        value: formData.bank_status,
                      },
                    })
                  }
                >
                  {formData?.bank_status == "1" ? "ACTIVE" : "INACTIVE"}
                </CreateClientButton>
              </BalanceContainer>
              <BalanceContainer>
                <BalanceLabel>Bank Name</BalanceLabel>
                <BalanceInput
                  type="text"
                  value={formData?.bank_name}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: "bank_name",
                        value: e.target.value,
                      },
                    })
                  }
                  placeholder="ABCXYZ Bank"
                />
              </BalanceContainer>

              <BalanceContainer>
                <BalanceLabel>Account Holder Name</BalanceLabel>
                <BalanceInput
                  type="text"
                  value={formData?.account_holder_name}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: "account_holder_name",
                        value: e.target.value,
                      },
                    })
                  }
                  placeholder="John David"
                />
              </BalanceContainer>

              <BalanceContainer>
                <BalanceLabel>Account No.</BalanceLabel>
                <BalanceInput
                  type="number"
                  value={formData?.account_no}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: "account_no",
                        value: e.target.value,
                      },
                    })
                  }
                  placeholder="00990909090"
                />
              </BalanceContainer>

              <BalanceContainer>
                <BalanceLabel>IFSC Code</BalanceLabel>
                <BalanceInput
                  type="text"
                  value={formData?.ifsc_code}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: "ifsc_code",
                        value: e.target.value,
                      },
                    })
                  }
                  placeholder="XYZIFSC001"
                />
              </BalanceContainer>

              <BalanceUpdateButton onClick={edit_payment}>
                UPDATE
              </BalanceUpdateButton>
            </BalanceWrapper>
          </RightPanelContainer>
        </div>
      </Card>
    </div>
  );
}
