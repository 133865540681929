const iState = {
  theme: 1, //0 dark, 1 light
  pfdata: [],
  pfdata2: [],
  pfdata3: [],
  buy: 0,
  sell: 0,
  total: 0,
  date: new Date(),
  total: 0,
  total2: 0,
  brokerages: 0,
  total3: 0,
  scriptdata: "",
};

const Reducer = (state = iState, action) => {
  switch (action.type) {
    case "THEME":
      return {
        ...state,
        theme: action.payload,
      };
    case "SCRIPTDATA":
      return {
        ...state,
        scriptdata: action.payload,
      };

    case "CLEAR_PF":
      return {
        ...state,
        pfdata: [],
        total: 0,
        buy: 0,
        sell: 0,
        brokerages: 0,
        date: new Date(),
      };
    case "CALC_PROFIT": {
      var olddata = state.pfdata;

      if (olddata.length > 0) {
        var found = 0;
        var data_to_push = [];
        olddata.map((i, t) => {
          if (i.id == action.data.id) {
            found = 1;
            data_to_push = t;
          }
        });
        if (found > 0) {
          olddata[data_to_push] = action.data;
        } else {
          olddata.push(action.data);
        }
      } else {
        olddata.push(action.data);
      }
      olddata.filter((item, index) => {
        if (olddata.indexOf(item) !== index) {
          olddata.splice(index, 1);
        }
      });
      var total = 0;
      var brokerker = 0;
      var buy = 0;
      var sell = 0;
      olddata.map((i) => {
        total = parseFloat(total) + parseFloat(i.pfit);
        brokerker = parseFloat(brokerker) + parseFloat(i.broker);
        // buy = parseFloat(buy) + parseFloat(i.buy);
        // sell = parseFloat(sell) + parseFloat(i.sell);
      });

      return {
        ...state,
        pfdata: olddata,
        total: total,
        buy: buy,
        sell: sell,
        brokerages: brokerker,
      };
    }
    case "CLEAR_PF3":
      return {
        ...state,
        pfdata3: [],
        total3: 0,
      };
    case "PF3": {
      if (action.total > 0) {
        var newpfdata = state.pfdata3;
        if (Array.isArray(newpfdata)) {
          var found = false;
          for (var i = 0; i < newpfdata.length; i++) {
            if (newpfdata[i].script_id == action.script_id) {
              found = i;
              continue;
            }
          }
          if (found === false) {
            newpfdata.push({
              script_id: action.script_id,
              pf: action.pf,
            });

            var total = 0;
            newpfdata.map((i) => {
              total = parseFloat(total) + parseFloat(i.pf);
            });
            return { ...state, pfdata3: newpfdata, total3: total };
          } else {
            newpfdata[found].pf = action.pf;
            var total = 0;
            newpfdata.map((i) => {
              total = parseFloat(total) + parseFloat(i.pf);
            });
            return { ...state, pfdata3: newpfdata, total3: total };
          }
        } else {
          newpfdata.push({
            script_id: action.script_id,
            pf: action.pf,
          });

          return { ...state, pfdata3: newpfdata, total3: action.pf };
        }
      }
    }
    default:
      return state;
  }
};

export default Reducer;
