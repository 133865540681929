import React, { useState, useEffect } from "react";
import styled from "styled-components";
import StatCard from "../StatCard";
import { apiCall } from "../../../Backend/Backend";
import { COLORSCHEMEA } from "../../../ColorSchema/ColorSchema";
import UpdateTrade from "../../Common/Update";

// Styled Components for the layout
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%; /* Make sure the container uses full width */
`;

const CardWrapper = styled.div`
  flex: 1 1 calc(50% - 20px); /* Adjust for two cards per row with space in between */
  /* margin: 10px; */
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex: 1 1 100%; /* On smaller screens, each card takes up the full width */
  }
`;

function formatKeyToTitle(key) {
  if (key.includes("profit_loss")) {
    key = key.replace("profit_loss", "P/L");
  }
  return key
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export default function RightViewTopComp() {
  const [homeData, setHomeData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadDashboard();
  }, []);

  const loadDashboard = async () => {
    const response = await apiCall("/load_dashboard");

    setLoading(false);
    if (response?.error === "False") {
      setHomeData(response.dashboard);
    } else {
      alert(response?.message);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <UpdateTrade update={() => loadDashboard()} />
      <Container>
        {Object.entries(homeData).map(([key, value]) => (
          <CardWrapper key={key}>
            <StatCard
              title={formatKeyToTitle(key)} // Use the utility function to format the title
              value={parseFloat(value).toFixed(0)} // Format the value
              valueColor={
                key.includes("profit_loss")
                  ? value >= 0
                    ? COLORSCHEMEA.GREENCOLOR
                    : COLORSCHEMEA.REDCOLOR
                  : COLORSCHEMEA.THEMEFONT
              }
            />
          </CardWrapper>
        ))}
      </Container>
    </>
  );
}
